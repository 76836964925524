import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

const Login = lazy(() => import("./components/Login/Login"));
const SignUp = lazy(() => import("./components/SignUp/SignUp"));
const ClassInfo = lazy(() =>
  import("./components/Classes/ClassInfo/ClassInfo")
);
const Classes = lazy(() => import("./components/Classes/Classes"));
const Home = lazy(() => import("./components/Home/Home"));
const Payment = lazy(() => import("./components/Payment/Payment"));
const Partners = lazy(() => import("./components/Partners/Partners"));

const AboutUsContainer = lazy(() =>
  import("./components/AboutUs/AboutUsContainer")
);

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/classes"
        element={
          <Suspense>
            <Classes />
          </Suspense>
        }
      />
      {/* <Route
        path="/classes/:id"
        element={
          <Suspense>
            <ClassInfo />
          </Suspense>
        }
      /> */}
      <Route
        path="/login"
        element={
          <Suspense>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/signup"
        element={
          <Suspense>
            <SignUp />
          </Suspense>
        }
      />
      <Route
        path="/home"
        element={
          <Suspense>
            <Home />
          </Suspense>
        }
      />
      <Route
        path="classes/payment"
        element={
          <Suspense>
            <Payment />
          </Suspense>
        }
      />
      <Route
        path="/aboutus"
        element={
          <Suspense>
            <AboutUsContainer />
          </Suspense>
        }
      />
      <Route
        path="/partners"
        element={
          <Suspense>
            <Partners />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/classes" />} />
    </Routes>
  );
};

export default AppRoutes;
