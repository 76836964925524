export const Menu = [
    {
        name: 'home',
        path: '/home'
    },
    {
        name: 'classes',
        path: '/classes'
    },
    {
        name: 'partners',
        path: '/partners'
    },
    {
        name: 'about_us',
        path: '/aboutUs'
    },
]

