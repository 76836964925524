import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../images/logo-1.png";
import { useTranslation, Trans } from "react-i18next";

import "./Footer.scss";


import {
  faMapMarker,
  faPhone,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const { t, i18n } = useTranslation("common");

  return (
    <footer className="footer">
      <div className="footer-header">
        <div className="logo">
          <img src={Logo} />
          <p className="welcome-text">{t("welcome_to_zen")}</p>
        </div>
        <div className="social-icons">
          <a
            href="https://www.instagram.com/zhest.psychologicalcenter/"
            target="_blank"
          >
            <span className="insta">
              <FontAwesomeIcon icon={faInstagram} />
            </span>
          </a>
          <a href="https://www.facebook.com/ZhestCenter/" target="_blank">
            <span className="fb">
              <FontAwesomeIcon icon={faFacebookF} />
            </span>
          </a>
          <a href="" target="_blank">
            <span className="linkedin">
              <FontAwesomeIcon icon={faLinkedin} />
            </span>
          </a>
          <a href="" target="_blank">
            <span className="twitter">
              <FontAwesomeIcon icon={faTwitter} />
            </span>
          </a>
        </div>
      </div>
      <div className="footer-info">
        <ul className="about-us">
          <li key="faMapMarker">
            <FontAwesomeIcon icon={faMapMarker} /> {t("address")}
          </li>
          <li key="faPhone">
            <FontAwesomeIcon icon={faPhone} /> 011 652 566
          </li>
          <li key="faPaperPlane">
            <FontAwesomeIcon icon={faPaperPlane} /> izenzinanoc@gmail.com
          </li>
          <li key="website">
            <a href="https://www.zhest.am/" target="_blank">
              www.zhest.am
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
