import React, {
  FC,
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faCheck,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";

import "./DropDown.scss";

interface DropDownProps {
  options: IOption[];
  value: number | string;
  onChange: CallableFunction
}

const DropDown: FC<DropDownProps> = ({ options, value, onChange }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  

  const toggleDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleLanguageSelect = (language: string) => {
    setSelectedValue(language);
    onChange(language);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const selectedOption = useMemo(() => {
    return options.find((opt) => opt.value === selectedValue);
  }, [selectedValue, options]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <button className="dropdown" onClick={toggleDropdown}>
        {selectedOption?.label}{" "}
        {isOpen ? (
          <FontAwesomeIcon icon={faAngleUp} />
        ) : (
          <FontAwesomeIcon icon={faAngleDown} />
        )}
      </button>
      {isOpen && (
        <div className="dropdown-content">
          {options.map((option) => (
            <button
              key={option.value}
              onClick={() => handleLanguageSelect(String(option.value))}
            >
              {option.label}{" "}
              {value === option.value && (
                <FontAwesomeIcon icon={faCheck} />
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
