import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import trans_am from "./translations/am/common.json";
import trans_en from "./translations/en/common.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
        am: {
        common: trans_am
      },
      en: {
        common: trans_en
      }
    },
    fallbackLng: "en",
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: true, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
