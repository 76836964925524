import { useState } from "react";
import { Link } from "react-router-dom";

import MobileMenu from "../MobileMenu/MobileMenu";
import { Menu } from "../../constans/Menu";
import useSelectedMenuItem from "../../hooks/useSelectedMenuItem";
import Logo from "../../images/logo-1.png";

import "./Header.scss";
import DropDown from "../uiKit/dropdown/DropDown";
import { OPTIONS } from "../../constans/Dropdowns";
import { useTranslation, Trans } from "react-i18next";

const Header = () => {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  const selectedMenuItem = useSelectedMenuItem();
  const { t, i18n } = useTranslation("common");

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={Logo} />
      </div>
      <div className="mobile-header" onClick={() => setMobileMenuOpened(true)}>
        <span className="menu-icon">&#9776;</span>{" "}
        <div className="welcome">
          {t("welcome_to_zen")}
          <img src={Logo} />
        </div>
      </div>
      {!mobileMenuOpened ? (
        <nav className="nav">
          <ul className="list">
            {Menu.map((item) => (
              <li
                key={item.path}
                className={selectedMenuItem === item.path ? "selected" : ""}
              >
                <Link to={item.path}>{t(item.name)}</Link>
              </li>
            ))}
          </ul>
        </nav>
      ) : (
        <MobileMenu toggleMenu={setMobileMenuOpened} />
      )}
      <DropDown
        options={OPTIONS}
        onChange={changeLanguage}
        value={i18n.language.split("-")[0]}
      />
      {/* <div className="login">
        <Link to="/login">Login</Link>
      </div> */}
    </header>
  );
};

export default Header;
