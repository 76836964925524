import { BrowserRouter as Router } from "react-router-dom";

import AppRoutes from "./Route";
import Header from "./components/Header/Header";
import "./App.scss";
import TawkLiveChat from "./components/Livechat/TawkLiveChat";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="container">
          <AppRoutes />
        </div>
        <TawkLiveChat/>
      </div>
      <Footer/>
    </Router>
  );
}

export default App;
