import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const TawkLiveChat = () => {
  return (
    <div className="live-chat">
      <TawkMessengerReact
        propertyId="66156bd11ec1082f04e0869a"
        widgetId="1hr1qqan7"
        className="my-chat"
      />
    </div>
  );
};

export default TawkLiveChat;
